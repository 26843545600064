@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=SUSE:wght@100..800&display=swap');

body {
  font-family: 'SUSE', sans-serif;
  background-color: #f9f9f9;
  margin: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes blink {
  50% { border-color: transparent; }
}

@keyframes fadeInUp {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
  40% { transform: translateY(-30px); }
  60% { transform: translateY(-15px); }
}

.typing-effect {
  display: inline-block;
  overflow: hidden;
  border-right: 3px solid rgba(255,255,255,0.75);
  white-space: nowrap;
  animation: typing 3s steps(40, end), blink 0.75s step-end infinite alternate;
}

@media (max-width: 768px) {
  .typing-effect {
    animation: none;
    border: none;
  }
}

.particles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: radial-gradient(circle, rgba(255,255,255,0.2) 20%, transparent 20%) no-repeat;
  background-size: 10px 10px;
  z-index: 10;
}

/* Custom Scrollbar Styles for WebKit Browsers */
::-webkit-scrollbar {
  width: 20px;
  height: 16px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(135deg, teal, teal);
  border-radius: 10px;
  border: 6px solid rgba(0, 0, 0, 0.2);
  transition: background 0.3s, transform 0.3s;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(135deg, teal, teal);
  transform: scale(1.1);
}

::-webkit-scrollbar-thumb:active {
  background: linear-gradient(135deg, teal, teal);
}

::-webkit-scrollbar-track:horizontal {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:horizontal {
  background: linear-gradient(135deg, teal, teal);
  border-radius: 10px;
  border: 4px solid rgba(0, 0, 0, 0.2);
  transition: background 0.3s, transform 0.3s;
}

/* Optional - Customize scrollbar for Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: teal rgba(0, 0, 0, 0.2);
}

@layer utilities {
  .fade-in {
    animation: fadeIn 0.8s ease-in-out;
  }

  .slide-down {
    animation: slideDown 1s ease-in-out;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideDown {
    0% {
  opacity: 0;
  transform: translateY(-20px);
}
100% {
  opacity: 1;
  transform: translateY(0);
}}}

@media print {
  button {
    display: none !important; /* Hide all buttons */
  }
}

h1, h3 {
  text-align: center;
  color: #ffffff;
  margin-bottom: 15px;
}

.test-section {
  background: #fff;
  margin: 20px 0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.test-section:hover {
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.table th, .table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: center;
  transition: background-color 0.3s ease;
}

.table th {
  background-color: #f2f2f2;
  color: #555;
}

.table tr:hover {
  background-color: #f9f9f9;
}

.form-group {
  margin: 15px 0;
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 5px;
  color: #555;
}

input[type="text"], 
input[type="number"], 
input[type="email"],
textarea {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

input[type="text"]:focus, 
input[type="number"]:focus, 
input[type="email"]:focus,
textarea:focus {
  border-color: #007bff;
  outline: none;
}

button {
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  color: white;
}

.error {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

/* Responsive Styles */
@media (max-width: 600px) {
  .test-section {
      padding: 15px;
  }

  .table th, .table td {
      padding: 10px;
      font-size: 14px;
  }

  input[type="text"], 
  input[type="number"], 
  input[type="email"],
  textarea {
      font-size: 14px;
  }

  button {
      padding: 8px 12px;
      font-size: 14px;
  }
}